let closestDate = Date.now() + 7 * 24 * 60 * 60 * 1000
const closestWeekDay = (new Date()).getUTCDay()
if (closestWeekDay === 0) {
  closestDate += 1 * 24 * 60 * 60 * 1000
} else if (closestWeekDay === 6) {
  closestDate += 2 * 24 * 60 * 60 * 1000
}

export default {
  name: 'selectSwitchDateForm',
  formTitle: '',
  navTitle: '',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: '',
      fields: [
        [{
          choice: 'single',
          field: 'datepicker',
          type: '',
          name: 'switchDate',
          veeAs: 'date',
          label: '',
          placeholder: '',
          icon: {
            name: 'today',
            position: 'right'
          },
          rules: 'required',
          openDate: new Date(closestDate),
          disabledDates: {
            to: new Date(closestDate - 24 * 60 * 60 * 1000),
            days: [0, 6]
          },
          selected: new Date(closestDate)
        }]
      ]
    }
  ]
}
