<template src="./switch-account.html"></template>
<style src="./switch-account.scss" lang='scss' scoped></style>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import { companyGetters } from '@/store/modules/company/routines'
import { bankGetters } from '@/store/modules/bank/routines'
import deepcopy from 'deepcopy'
import selectBanksForm from './select-banks.form'
import selectSwitchDateForm from './select-switch-date.form'
import switchAccountForm from './switch-account.form'
import ProgressBubbles from '@/components/ProgressBubbles'
import DynamicForm from '@/components/forms/DynamicForm'
import SwoopLogo from '@/components/SwoopLogo'
import { getBankAccountList, getBankAccountDirectDebitContracts, getBankAccountStandingOrders, getBankStatementsReport, switchBankAccountReport } from '@/api/bankAccount'
import { getBankAccountReportDetails } from '@/api/switchBank'
import { getAllSwitchableBanks } from '@/api/openBankingOpenData'
import { getCompanyOfficers } from '@/api/company'
import { downloadFile, getOfficerName } from '@/utils/utils'
import { prefillFormData } from '@/utils/formHelpers'
import { amplitudeEvents } from '@/utils/constants'
import CurrencySymbol from '@/components/CurrencySymbol'
import Vue from 'vue'
import { ClientTable } from 'vue-tables-2'
import vueTablesConf from '@/config/vue-tables'

Vue.use(ClientTable, vueTablesConf.options, vueTablesConf.useVuex, vueTablesConf.theme, vueTablesConf.template)

export default {
  name: 'SwitchAccount',
  components: {
    CurrencySymbol,
    ProgressBubbles,
    DynamicForm,
    ValidationProvider,
    SwoopLogo
  },
  data() {
    return {
      progressSteps: ['Before you switch', 'Getting ready', 'Review'],
      currentStep: 0,
      selectBanksForm: deepcopy(selectBanksForm),
      selectSwitchDateForm: deepcopy(selectSwitchDateForm),
      switchAccountForm: deepcopy(switchAccountForm),
      selectedBanksSubmitPromise: null,
      selectSwitchDateSubmitPromise: null,
      submitSwitchAccountPromise: null,
      sourceBankAccountId: null,
      switchBankName: null,
      banksList: null,
      bankAccounts: null,
      bankAccountDirectDebitContracts: null,
      isStatementsAddToDocuments: true,
      switchDate: null,
      loading: {},
      paymentHistoryTableData: null,
      paymentHistoryTableColumns: ['no', 'merchantName', 'amount', 'action'],
      paymentHistoryTableOptions: {
        uniqueKey: 'standingOrderId',
        skin: 'table table-hover table-v-align swoop-grid',
        filterable: false,
        headings: {
          no: 'No.',
          merchantName: 'To'
        },
        sortable: ['no', 'merchantName', 'amount']
      }
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      bankMandateId: bankGetters.BANK_MANDATE_ID
    }),
    switchBankFullName() {
      if (!this.banksList || !this.switchBankName) return ''
      const bank = this.banksList.find(bank => bank.value === this.switchBankName)
      return bank ? bank.label : ''
    },
    bubbleStep() {
      if (this.currentStep === 1) {
        return 1
      } else if (this.currentStep < 7) {
        return 2
      } else {
        return 3
      }
    }
  },
  methods: {
    async moveToStep(n) {
      switch (n) {
        case 1:
          this.currentStep = 1
          break
        case 2:
          this.$set(this.loading, 'step2', true)
          this.currentStep = 2
          await this.prefillSelectBanksFormData()
          this.loading.step2 = false
          break
        case 3:
          this.$set(this.loading, 'step3', true)
          this.currentStep = 3
          await this.fillDirectDebitContracts()
          this.loading.step3 = false
          break
        case 4:
          this.$set(this.loading, 'step4', true)
          this.currentStep = 4
          await this.fillPaymentHistoryTableData()
          this.loading.step4 = false
          break
        case 5:
          this.currentStep = 5
          break
        case 6:
          this.currentStep = 6
          break
        case 7:
          this.$set(this.loading, 'step7', true)
          this.currentStep = 7
          await this.prefillSwitchAccountForm()
          this.loading.step7 = false
          break
        case 8:
          this.currentStep = 8
          break
      }
    },
    prefillSelectBanksFormData() {
      return Promise.all([getBankAccountList(this.companyId).then(res => res.data), getAllSwitchableBanks().then(res => res.data)])
        .then(([bankAccounts, banksList]) => {
          this.bankAccounts = bankAccounts
          this.banksList = banksList.map(({ name, fullName }) => ({ value: name, label: fullName }))
          const replaceOptions = {
            sourceBankAccountId: bankAccounts.map(({ bankAccountId, displayName }) => ({ value: bankAccountId, label: displayName })),
            switchBank: this.banksList
          }
          prefillFormData(this.selectBanksForm, {}, replaceOptions)
        })
        .catch(e => {
          this.loading.step2 = false
        })
    },
    submitSelectedBanks(formData) {
      const selectedBank = this.banksList.filter(bank => bank.value === formData.data.switchBank)
      const bankName = selectedBank[0].label
      this.selectedBanksSubmitPromise = null
      this.sourceBankAccountId = formData.data.sourceBankAccountId
      this.switchBankName = formData.data.switchBank
      /** Amplitude Analytics */
      this.$ma.trackEvent({
        eventType: amplitudeEvents.savingCheckout.CHOOSE_BANK_NEXT_CLICKED,
        eventProperties: {
          'Switch Bank From Bank Name': '',
          'Switch Bank From Account Name': '',
          'Bank Account To': bankName
        }
      })
      this.moveToStep(3)
    },
    sendChooseBankBackToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.CHOOSE_BANK_BACK_CLICKED)
    },
    submitSelectSwitchDate(formData) {
      this.selectSwitchDateSubmitPromise = null
      this.switchDate = formData.data.switchDate
      this.sendSwitchDateNextToAmplitude()
      this.moveToStep(7)
    },
    fillDirectDebitContracts() {
      return getBankAccountDirectDebitContracts(this.sourceBankAccountId)
        .then(res => {
          this.bankAccountDirectDebitContracts = res.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    fillPaymentHistoryTableData() {
      return getBankAccountStandingOrders(this.sourceBankAccountId)
        .then(res => {
          this.paymentHistoryTableData = res.data.map(payment => ({ ...payment, selected: 'switch' }))
        })
        .catch(e => {
          this.paymentHistoryTableData = []
        })
    },
    paymentHistorySwitchHandler(standingOrderId, value) {
      const standingOrder = this.paymentHistoryTableData.find(standingOrder => standingOrder.standingOrderId === standingOrderId)
      if (!standingOrder) return
      standingOrder.selected = value
    },
    downloadBankStatementsClickHandler() {
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.DOWNLOAD_BANK_STAT)
      return getBankStatementsReport(this.companyId, this.sourceBankAccountId, this.isStatementsAddToDocuments)
        .then(res => {
          downloadFile(res.data, 'BankStatements.pdf')
        })
        .catch(e => {
          console.log(e)
        })
    },
    prefillSwitchAccountForm() {
      return Promise.all([getBankAccountReportDetails(this.companyId, this.bankMandateId), getCompanyOfficers(this.companyId)])
        .then(([resReport, resOfficers]) => {
          const officerOptions = resOfficers.data.map(officer => ({ value: officer.officerId, label: getOfficerName(officer) }))
          officerOptions.push({ value: 'Other', label: 'Other' })

          const formOptions = {
            officerId: officerOptions
          }
          const formPrefillData = {
            ...resReport.data,
            officerId: resReport.data.officerId === null ? 'Other' : resReport.data.officerId
          }

          prefillFormData(this.switchAccountForm, formPrefillData, formOptions)
        })
        .catch(e => {
          console.log(e)
        })
    },
    submitSwitchAccountForm(formData) {
      const { isAddToDocuments, ...switchBankDetails } = formData.data
      if (switchBankDetails.officerId === 'Other') {
        switchBankDetails.officerId = null
      }
      const data = {
        companyId: this.companyId,
        sourceBankAccountId: this.sourceBankAccountId,
        bankName: this.switchBankName,
        transactionIds: this.paymentHistoryTableData.filter(payment => payment.selected === 'ignore').map(payment => payment.standingOrderId),
        switchDate: this.switchDate,
        isAddToDocuments,
        switchBankDetails
      }
      this.submitSwitchAccountPromise = switchBankAccountReport(data).then(res => {
        downloadFile(res.data, 'ChangeBankAccount.pdf')
        this.moveToStep(8)
      })
      /** Amplitude Analytics */
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.REVIEW_PAGE_START_CLICKED)
      return this.submitSwitchAccountPromise
    },
    hackPagination(page) {
      this.$refs.paymentHistory.$children.filter(component => component.setPage).forEach(pagination => (pagination.Page = page))
    },
    sendReviewPageBackClickToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.REVIEW_PAGE_BACK_CLICKED)
    },
    sendNextToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.setUserProperties({
        'Bank Account Product': this.$route.params.bankName
      })
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.SWITCH_START_NEXT_CLICKED)
    },
    sendEligibilityToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.setUserProperties({
        'Direct debits count': this.bankAccountDirectDebitContracts ? this.bankAccountDirectDebitContracts.length : 0
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.savingCheckout.ELIGIBILITY_NEXT_CLICKED,
        eventProperties: {
          'Direct debits count': this.bankAccountDirectDebitContracts ? this.bankAccountDirectDebitContracts.length : 0
        }
      })
    },
    sendEligibilityBackClickToAmplitude() {
      /** Amplitude Analytics */
      /** Amplitude Analytics */
      this.$ma.setUserProperties({
        'Direct debits count': this.bankAccountDirectDebitContracts ? this.bankAccountDirectDebitContracts.length : 0
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.savingCheckout.ELIGIBILITY_BACK_CLICKED,
        eventProperties: {
          'Direct debits count': this.bankAccountDirectDebitContracts ? this.bankAccountDirectDebitContracts.length : 0
        }
      })
    },
    sendStandingOrdersToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.STANDING_ORDERS_NEXT_CLICKED)
    },
    sendStandingOrdersBackClickToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.STANDING_ORDERS_BACK_CLICKED)
    },
    sendDocumentsNextToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.DOCUMENTS_NEXT_CLICKED)
    },
    sendDocumentsBackClickToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.DOCUMENTS_BACK_CLICKED)
    },
    sendSwitchDateNextToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.setUserProperties({
        'Switch Date': this.switchDate
      })
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.SWITCH_DATE_NEXT_CLICKED)
    },
    sendSwitchDateBackClickToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.setUserProperties({
        'Switch Date': this.switchDate
      })
      this.$ma.trackEvent(amplitudeEvents.savingCheckout.SWITCH_DATE_BACK_CLICKED)
    }
  },
  created() {
    if (this.$route.params.bankName) {
      prefillFormData(this.selectBanksForm, { switchBank: this.$route.params.bankName })
    }
    this.moveToStep(1)
  }
}
</script>
