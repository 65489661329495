import { countriesOptions, nationalityOptions } from '@/utils/countries'
import { webUrlRegExp, titleOptions, genderOptions, interestedCategoryNamesOptions } from '@/utils/constants'
import config from '@/config/global'
import dictionary from '@/config/dictionary'

export default {
  name: 'switchAccountForm',
  formTitle: '',
  navTitle: '',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: 'About You',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'firstName',
            veeAs: 'first name',
            label: 'First Name',
            placeholder: '',
            rules: 'required',
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'lastName',
            veeAs: 'last name',
            label: 'Last Name',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            isInline: false,
            name: 'title',
            veeAs: ' ',
            label: 'Title',
            placeholder: '',
            rules: 'required',
            columns: 2,
            options: titleOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'datepicker',
            type: '',
            name: 'doB',
            veeAs: 'day of birth',
            label: 'Day of Birth',
            placeholder: '',
            icon: {
              name: 'today',
              position: 'right'
            },
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            isInline: false,
            name: 'gender',
            veeAs: ' ',
            label: 'Gender',
            placeholder: '',
            rules: 'required',
            options: genderOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'nationality',
            veeAs: 'nationality',
            label: 'Nationality',
            placeholder: '',
            rules: 'required',
            options: nationalityOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isDualNationalityHolder',
            veeAs: ' ',
            label: 'Dual Nationality Holder?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'otherDualNationality',
            veeAs: 'other nationality',
            label: 'What is your other dual nationality?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isDualNationalityHolder',
                values: [true]
              }
            ],
            options: nationalityOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'countryOfResidence',
            veeAs: 'country of residence',
            label: 'Country of Residence',
            placeholder: '',
            rules: 'required',
            options: countriesOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'email',
            veeAs: 'email',
            label: 'Your Email Address',
            placeholder: '',
            rules: 'required|email',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'tel',
            name: 'contactNumber',
            veeAs: 'contact number',
            label: 'Contact Number',
            placeholder: '+44 (ХХХ) XXXXXXX',
            icon: {
              name: 'phone',
              position: 'right'
            },
            rules: 'required',
            selected: ''
          }
        ]
      ]
    },
    {
      title: 'About Your Business',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'companyName',
            label: 'What is your company name?',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'nameOfBusiness',
            veeAs: 'trading name',
            label: 'Your trading name?',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            name: 'officerId',
            veeAs: ' ',
            label: 'Are you one of these officers?',
            placeholder: '',
            rules: 'required',
            columns: 2,
            options: [],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'otherFirstName',
            veeAs: ' ',
            label: 'Officer First Name',
            placeholder: '',
            rules: 'required',
            selected: '',
            dependsOn: [
              {
                fieldName: 'officerId',
                values: ['Other']
              }
            ]
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'otherLastName',
            veeAs: ' ',
            label: 'Officer Last Name',
            placeholder: '',
            rules: 'required',
            selected: '',
            dependsOn: [
              {
                fieldName: 'officerId',
                values: ['Other']
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'shareholdingStatus',
            veeAs: ' ',
            label: 'Shareholding status',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Shareholder', value: 'Shareholder' },
              { label: 'Not a shareholder', value: 'Not a shareholder' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'shareholding',
            veeAs: 'shareholding ',
            label: 'What is your shareholding within the organisation?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'shareholdingStatus',
                values: ['Shareholder']
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'hasYourBusinessStartedTrading',
            veeAs: ' ',
            label: 'Has your business started trading?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'datepicker',
            type: '',
            name: 'trandingStartedDate',
            veeAs: ' ',
            label: 'When did you Start trading?',
            placeholder: '',
            icon: {
              name: 'today',
              position: 'right'
            },
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'hasYourBusinessStartedTrading',
                values: [true]
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'url',
            name: 'website',
            veeAs: 'website',
            label: 'Please enter your business website if you have one.',
            placeholder: '',
            rules: {
              regex: webUrlRegExp
            },
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'town',
            veeAs: 'town',
            label: 'What is your Town/City?',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'registeredAddressLine',
            veeAs: 'registered address',
            label: 'What is your registered address?',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'postcode',
            veeAs: 'postcode',
            label: 'What is your postcode?',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'typeOfPremisesOfTradingAddress',
            veeAs: 'type of premises',
            label: 'What type of premises is your trading address?',
            placeholder: '',
            rules: 'required',
            options: [
              {
                label: 'Shop',
                value: 'Shop'
              },
              {
                label: 'Warehouse',
                value: 'Warehouse'
              },
              {
                label: 'Factory',
                value: 'Factory'
              },
              {
                label: 'Office',
                value: 'Office'
              },
              {
                label: 'Home',
                value: 'Home'
              },
              {
                label: 'Other',
                value: 'Other'
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'numberOfPremises',
            veeAs: ' ',
            label: 'How many premises, offices, outlets do you have it total?',
            placeholder: '',
            rules: 'required|numeric',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'doesBusinessPremisesAbroad',
            veeAs: ' ',
            label: 'Does your business have premises abroad?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isPremissesInRiskCountry',
            veeAs: ' ',
            label:
              'Can you confirm if your business has premisses in any of these countries (Afghanistan, CP, Cuba, Ecuador, Iran, Iraq, North Korea, Lebanon, Libya, Myanmar, Panama, Sudan, Syria, Ukraine)?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'doesBusinessPremisesAbroad',
                values: [true]
              }
            ],
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
              { label: 'Maybe', value: 'Maybe' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'countryOfIncorporation',
            veeAs: 'country',
            label: 'What is your country of incorporation/establishment?',
            placeholder: '',
            rules: 'required',
            options: countriesOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isRegisteredForTaxOutsideUK',
            veeAs: ' ',
            label: 'Are you registered for tax outside the UK?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'taxOutsideUK',
            veeAs: ' ',
            label: 'What is the country and what is the tax identification number?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isRegisteredForTaxOutsideUK',
                values: [true]
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'doYouHaveParentCompany',
            veeAs: ' ',
            label: 'Do you have a parent company, headquarters or beneficial owner?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'textarea',
            type: 'text',
            name: 'servicesYourBusinessOffers',
            veeAs: ' ',
            label: 'Please tell us a bit about the goods and services your business offers, your customers and the industry where you operate.',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'countryOfParentCompany',
            veeAs: 'country',
            label: 'State which country the parent company, headquarters or beneficial owner is based?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'doYouHaveParentCompany',
                values: [true]
              }
            ],
            options: countriesOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isYourBusinessAuthorisedByPPA',
            veeAs: ' ',
            label: 'Is your business authorised by the Prudential Regulation Authority and/or regulated by the Financial Conduct Authority?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'hasBusinessSuppliersOutsideUK',
            veeAs: ' ',
            label: 'Will your business or organisations have any suppliers outside the UK?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'mainSuppliersBased',
            veeAs: ' ',
            label: 'Where are your main suppliers based?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'hasBusinessSuppliersOutsideUK',
                values: [true]
              }
            ],
            options: countriesOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'hasYourBusinessClientsOfIncomeOutsideOfTheUK',
            veeAs: ' ',
            label: 'Will your business or organisation have any customers, clients, members or other sources of income outside of the UK?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'multiple',
            field: 'select',
            type: '',
            name: 'mainCustomersCountries',
            veeAs: ' ',
            label: 'Which countries are your main customers in? (multi-select)',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'hasYourBusinessClientsOfIncomeOutsideOfTheUK',
                values: [true]
              }
            ],
            options: countriesOptions,
            selected: []
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'doesYourBusinessOfAnyIndustriesList',
            veeAs: ' ',
            label:
              'Does your business, your suppliers or your customers offer services of any of the industries below - Currency exchange or money transfer, Defence or weapons, Oil, gas or mineral extraction, Gambling or betting, Consumer credit or money lending?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ]
      ]
    },
    {
      title: 'About Your Bank Account',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'currentBankName',
            veeAs: ' ',
            label: 'What is the name of your current bank?',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'accountNumber',
            veeAs: ' ',
            label: 'What is your account number?',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'sortCode',
            veeAs: ' ',
            label: 'What is your sort code?',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'textarea',
            type: 'text',
            name: 'reasonOfMovingAccounts',
            veeAs: ' ',
            label: 'Please explain the reason for moving your account to us.',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            name: 'importantBankProperties',
            veeAs: 'cost savings',
            label: 'When considering a bank account, which of the following is most important to you?',
            placeholder: '',
            rules: 'required',
            columns: 1,
            options: [
              { label: 'Overdraft Facility', value: 'Overdraft Facility' },
              { label: 'Account Fees', value: 'Account Fees' },
              { label: 'FX Fees', value: 'FX Fees' },
              { label: 'International Payments', value: 'International Payments' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'multiple',
            field: 'input',
            type: 'checkbox',
            name: 'interestedCategoryNames',
            veeAs: 'cost savings',
            label: 'Which of the following does your business spend money on?',
            placeholder: '',
            rules: 'required',
            columns: 1,
            options: interestedCategoryNamesOptions,
            selected: []
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'doesYourBusinessHoldAnyFundsOnBehalfOfYourCustomers',
            veeAs: ' ',
            label: 'Does your business hold any funds on behalf of your customers?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'turnoverWillBePaidIntoThisAccount',
            veeAs: ' ',
            label: `How much of your ${dictionary.turnover} will be paid into this account?`,
            placeholder: '',
            rules: 'required|decimal',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'amountOfFirstDeposit',
            veeAs: ' ',
            label: 'What do you estimate the amount of the first deposit into this account to be?',
            placeholder: '',
            rules: 'required|decimal',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'expectToReceiveFirstDeposit',
            veeAs: ' ',
            label: 'How do you expect to receive the first deposit into this account?',
            placeholder: '',
            rules: 'required',
            options: [
              {
                label: 'Electronic transfer (domestic)',
                value: 'Electronic transfer (domestic)'
              },
              {
                label: 'Electronic transfer (international)',
                value: 'Electronic transfer (international)'
              },
              {
                label: 'Cheque deposit',
                value: 'Cheque deposit'
              },
              {
                label: 'Cash deposit',
                value: 'Cash deposit'
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'moneyForFirstDepositeComeFrom',
            veeAs: ' ',
            label: 'Where will the money for your first deposit come from?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Contract payment', value: 'Contract payment' },
              { label: 'Donations', value: 'Donations' },
              { label: 'Gifts', value: 'Gifts' },
              { label: 'Grant', value: 'Grant' },
              { label: 'Inheritance', value: 'Inheritance' },
              { label: 'Loans', value: 'Loans' },
              { label: 'Other income sources', value: 'Other income sources' },
              { label: 'Other investment sale', value: 'Other investment sale' },
              { label: 'Payment for services', value: 'Payment for services' },
              { label: 'Personal savings', value: 'Personal savings' },
              { label: 'Property sale', value: 'Property sale' },
              { label: 'Redundancy', value: 'Redundancy' },
              { label: 'Sale of goods', value: 'Sale of goods' },
              { label: 'Share sale', value: 'Share sale' },
              { label: 'Sponsorship', value: 'Sponsorship' },
              { label: 'Syndicate', value: 'Syndicate' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isMakeReceiveElectronicPayments',
            veeAs: ' ',
            label: 'Will your business make or receive electronic payments?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'electronicPaymentsReceivePeriod',
            veeAs: ' ',
            label: 'How often will you receive electronic payments into this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveElectronicPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'electronicPaymentsMakePeriod',
            veeAs: ' ',
            label: 'How often will you make electronic payments from this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveElectronicPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isMakeReceiveCardPayments',
            veeAs: ' ',
            label: 'Will your business make or receive card payments?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'cardPaymentsReceivePeriod',
            veeAs: ' ',
            label: 'How often will you receive card payments into this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveCardPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'cardPaymentsMakePeriod',
            veeAs: ' ',
            label: 'How often will you make card payments from this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveCardPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isMakeReceiveChequePayments',
            veeAs: ' ',
            label: 'Will your business make or receive cheque payments?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'chequePaymentsReceivePeriod',
            veeAs: ' ',
            label: 'How often will you receive cheque payments into this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveChequePayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'chequePaymentsMakePeriod',
            veeAs: ' ',
            label: 'How often will you make cheque payments from this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveChequePayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isMakeReceiveCashPayments',
            veeAs: ' ',
            label: 'Will your business make or receive cash payments?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'cashPaymentsReceivePeriod',
            veeAs: ' ',
            label: 'How often will you receive cash payments into this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveCashPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'cashPaymentsMakePeriod',
            veeAs: ' ',
            label: 'How often will you make cash payments from this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveCashPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isMakeReceiveOverseasPayments',
            veeAs: ' ',
            label: 'Will your business make or receive overseas payments?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'overseasPaymentsReceivePeriod',
            veeAs: ' ',
            label: 'How often will you receive overseas payments into this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveOverseasPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'overseasPaymentsMakePeriod',
            veeAs: ' ',
            label: 'How often will you make overseas payments from this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceiveOverseasPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isMakeReceivePostalPayments',
            veeAs: ' ',
            label: 'Will your business make or receive any postal order payments?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'postalPaymentsReceivePeriod',
            veeAs: ' ',
            label: 'How often will you receive postal payments into this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceivePostalPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: false,
            name: 'postalPaymentsMakePeriod',
            veeAs: ' ',
            label: 'How often will you make postal payments from this account?',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isMakeReceivePostalPayments',
                values: [true]
              }
            ],
            options: [
              { label: 'Daily', value: 'Daily' },
              { label: 'Weekly', value: 'Weekly' },
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Yearly', value: 'Yearly' },
              { label: 'Not applicable', value: 'Not applicable' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'multiple',
            field: 'input',
            type: 'checkbox',
            name: 'optionsToIncludeToAccount',
            veeAs: ' ',
            label: "Select which of the following options you'd like to include in this account",
            placeholder: '',
            rules: 'required',
            columns: 1,
            options: [
              { label: 'Internet Banking', value: 'Internet Banking' },
              { label: 'Debit Card', value: 'Debit Card' },
              { label: 'Cheque and Paying-in book', value: 'Cheque and Paying-in book' }
            ],
            selected: []
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'checkbox',
            name: 'isAddToDocuments',
            veeAs: ' ',
            label: 'Add To Documents?',
            placeholder: '',
            rules: 'required',
            selected: true
          }
        ]
      ]
    }
  ]
}
