export default {
  name: 'selectBanksForm',
  formTitle: '',
  navTitle: '',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: '',
      fields: [
        [{
          choice: 'single',
          field: 'select',
          type: '',
          name: 'sourceBankAccountId',
          veeAs: 'your bank account',
          label: 'Selected Bank Account:',
          placeholder: '',
          rules: 'required',
          options: [],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'select',
          type: '',
          name: 'switchBank',
          veeAs: 'bank account',
          label: 'Switch Bank Account:',
          placeholder: '',
          rules: 'required',
          options: [],
          selected: ''
        }]
      ]
    }
  ]
}
